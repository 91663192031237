<template>
<!-- 供应商条码管理 -->
    <div class="list">
      <list-search  @onSearch="onSearch" @clearSearch="onSearch" :childData="childData"></list-search>
      <global-table @pageChange="pageChange" :tableField="tableField" @tableAction="tableAction" :tableData="tableData" :paginationData="paginationData"></global-table>
      <dialog-box ref="barcodeDialogbody" dialogWidth="750px" :formdata="addFormdata" :dialogShow="dialogShow" @handleClose='handleClose' componentName="barcodeDialogbody"  title="申请条码"
      @cancelApplay="cancelApplay" @confirmBtn="confirmBtn"
   :buttonData="[{ label: '取消', action: 'cancelApplay', size: 'mini' },{label:'确认',action:'confirmBtn', type:'primary', size:'mini'}]" >
      </dialog-box>
      <dialog-box  :formdata="addFormdata" dialogWidth="750px" :dialogShow="seeCodeDialogShow" @handleClose='handleClose' componentName="SeebarcodeDialogbody" title="查看条码"
      @applyCodeBtn="applyCodeBtn" @closeApplay= "closeApplay"
   :buttonData="[{ label: '申请条码', action: 'applyCodeBtn',type:'primary', size: 'mini' },{label:'关闭',action:'closeApplay',  size:'mini'}]">
     </dialog-box>
    </div>
</template>

<script>
import ListSearch from '@/components/actionList/ListSearch.vue'
import GlobalTable from '@/components/actionList/GlobalTable.vue'
import DialogBox from '@/components/plug/DialogBox.vue'
import { MIME } from '@/assets/js/mime.js'
import { request, requestForm, exportForm } from '@/assets/js/http.js'
import { SearchList, SearchData, tableField, tableData } from './js/supbarcodeManagements'
import { FlowCtlData } from '@/assets/js/FlowManage.js'
export default {
  components: { DialogBox, ListSearch, GlobalTable },
  name: 'SupbarcodeManagement',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 330) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    FlowCtlData.docid = ''
    return {
      dialogShow: false,
      seeCodeDialogShow: false,
      tableField: tableField,
      tableData: tableData,
      pageSize: pageSize,
      addFormdata: {},
      paginationData: {},
      pageNum: 1,
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },
  created: function () {
    FlowCtlData.flowid = 'BarCode'
    FlowCtlData.initialize()
    this.getTableData()
  },
  methods: {
    tableAction: function (index, data, act) {
      this.addFormdata = data
      if (act.id === 'download') {
        var file = {}
        file.name = '导出报表.xls'
        file.id = data.id
        this.handlePreview(file)
      }
      if (act.id === 'barcode') {
        this.dialogShow = true
      }
      if (act.id === 'see') {
        this.seeCodeDialogShow = true
      }
    },
    handlePreview (file) {
      exportForm('/api/barcode/barcode/poi?id=' + file.id, 'post').then(
        (response) => {
          var fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase()
          var mime = 'application/octet-stream'
          if (MIME[fileExtension]) {
            mime = MIME[fileExtension]
          }
          const aBlob = new Blob([response.data], { type: mime })
          this.downloadByBlob(aBlob, file.name, mime)
        })
    },
    getTableData: function () {
      var searchdata = { ...this.childData.searchData }
      searchdata.pageNum = this.pageNum
      searchdata.pageSize = this.pageSize
      requestForm('/api/barcode/barcode/queryAll', 'post', searchdata).then((response) => {
        if (response.code === '200') {
          this.tableData = response.data.records
          if (response.data.total !== this.paginationData.total) {
            this.paginationData = { pageSize: this.pageSize, total: response.data.total }
          }
        }
      })
    },
    pageChange: function (data) {
      this.pageNum = data
      this.getTableData()
    },
    onSearch: function () {
      this.getTableData()
    },
    handleClose () {
      this.dialogShow = false // 关闭按钮
      this.seeCodeDialogShow = false
    },
    cancelApplay () { // 申请条码取消按钮
      this.dialogShow = false
    },
    confirmBtn () {
      for (var val of this.$refs.barcodeDialogbody.$refs.content.tableData) {
        if (val.id === 'barCode') {
          this.addFormdata.barcodeAdditional = val.number
          this.addFormdata.barcodeReason = val.reason
        }
        if (val.id === 'mac') {
          this.addFormdata.macAdditional = val.number
          this.addFormdata.macReason = val.reason
        }
        if (val.id === 'wwn') {
          this.addFormdata.wwnAdditional = val.number
          this.addFormdata.wwnReason = val.reason
        }
      }
      delete this.addFormdata.createDate
      delete this.addFormdata.modifyDate
      request(`/api/supplier/basicData/getByErpCode/${this.addFormdata.supplierCode}`, 'get').then((response) => {
        if (response.code === '200') {
          if (response.data === null || response.data === 'null') {
            this.$message({
              message: '未找到采购员！',
              type: 'error'
            })
            return false
          }
          this.addFormdata.buyer = response.data.buyerCode
          FlowCtlData.getNodeAction('TJ', this.addFormdata).then(
            (val) => {
              if (val) {
                FlowCtlData.alertDialog(this).then(() => {
                  FlowCtlData.setFormData(FlowCtlData, this.addFormdata)
                  requestForm('/api/barcode/barcode/applyBarCode', 'post', this.addFormdata).then((response) => {
                    if (response.code === '200') {
                      FlowCtlData.docid = response.data.id
                      FlowCtlData.setNodeAction().then((val) => {
                        this.$message({
                          message: '成功',
                          type: 'success'
                        })
                        FlowCtlData.docid = ''
                      })
                    }
                    this.dialogShow = false
                  })
                })
              }
            }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消提交'
            })
          })
        }
      })
      // this.dialogShow = false
    },
    applyCodeBtn () {
      this.dialogShow = true
      this.seeCodeDialogShow = false
    },
    closeApplay () {
      this.seeCodeDialogShow = false
    },
    downloadByBlob (data, fileName, mime) {
      try {
        const url = window.URL.createObjectURL(data)
        const eleLink = document.createElement('a')
        eleLink.href = url
        eleLink.target = '_blank'
        if (mime.indexOf('image') < 0 && mime.indexOf('pdf') < 0) {
          eleLink.download = fileName
        }
        eleLink.click()
        window.URL.revokeObjectURL(url)
      } catch (error) {
        console.error('download function error!', error)
      }
    }
  }
}
</script>
<style scoped lang="scss">
 @import '@/assets/css/tablePadding.scss';
</style>
