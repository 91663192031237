export var SearchList = [
  { labe: '采购单编号', code: 'purchaseOrderNumber', type: 'input' },
  { labe: '物料编码', code: 'itemNumber', type: 'input' },
  { labe: '物料描述', code: 'itemMessage', type: 'input' },
  { labe: '公司名称', code: 'corporateName', type: 'input', placeholder: '编码/名称' }

]
export var SearchData = {
  purchaseOrderNumber: '',
  itemNumber: '',
  itemMessage: '',
  corporateName: ''
}

export var tableField = [
  { label: '采购单编号', code: 'purchaseOrderNumber', type: 'input', width: '110' },
  { label: '采购单行号', code: 'lineNumber', type: 'input', width: '100' },
  { label: '销售单编号', code: 'salesOrderNumber', type: 'input', width: '100' },
  { label: '物料编号', code: 'itemNumber', type: 'input', width: '110' },
  { label: '物料描述', code: 'itemMessage', type: 'input', width: '110' },
  { label: '我的物料编号', code: 'myItemNumber', type: 'input', width: '110' },
  { label: '我的物料描述', code: 'myMaterialDescription', type: 'input', width: '110' },
  { label: '订单数量', code: 'barcodeQuantity', type: 'input', width: '' },
  { label: '公司名称', code: 'corporateName', type: 'input', width: '110' },
  { label: '条码数量', code: 'barcodeIssue', type: 'input', width: '' },
  {
    label: 'MAC量/总数量',
    code: 'macIssue',
    type: 'function',
    width: '130px',
    handle (index, row) {
      return `${row.macBase}/${row.macIssue}`
    }
  },
  {
    label: 'WWN量/总数量',
    code: 'wwnIssue',
    type: 'function',
    width: '130px',
    handle (index, row) {
      return `${row.wwnBase}/${row.wwnIssue}`
    }
  },

  {
    label: '操作',
    code: 'act',
    width: '140px',
    fixed: 'right',
    type: 'action',
    actionData: [
      {
        actionLabel: '申请',
        id: 'barcode'
      },
      {
        actionLabel: '查看',
        id: 'see'
      },
      {
        actionLabel: '下载',
        id: 'download'
      }
    ]
  }
]

export var tableData = []
